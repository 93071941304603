import { Component, forwardRef, Input, ViewEncapsulation, output } from '@angular/core';
import { type CommunicationRole } from '../../models/communication-role';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';

import { FocusableDirective } from '@core/directives/focusable.directive';
import { CheckboxComponent } from '@design/forms/checkbox/checkbox.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'communication-roles-selector',
  templateUrl: './communication-roles.component.html',
  styleUrls: ['./communication-roles.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommunicationRolesComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgTemplateOutlet, FocusableDirective, CheckboxComponent, TranslateModule],
})
export class CommunicationRolesComponent implements ControlValueAccessor {
  @Input() roles: CommunicationRole[] = [];
  @Input() compactMode = false;
  @Input() canInviteUsers = true;
  inviteClick = output<CommunicationRole>();
  public disabled = false;
  public _value: string[] = [];

  public get value(): string[] {
    return this._value;
  }

  public set value(val: string[]) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  public get rolesCenterIndex(): number {
    return Math.round(this.roles.length / 2);
  }

  public isRoleSelected(role: CommunicationRole): boolean {
    return this.value.findIndex((key) => key === role.key) !== -1;
  }

  public changeSelection(role: CommunicationRole): void {
    if (this.disabled) {
      return;
    }

    if (this.isRoleSelected(role)) {
      this.value = this.value.filter((key) => key !== role.key);
    } else {
      this.value = [...this.value, role.key];
    }
  }

  public writeValue(value: string[]): void {
    this._value = value || [];
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onPlusClick(role: CommunicationRole | any): void {
    this.inviteClick.emit(role);
  }

  private onChange = (val: string[]) => {};

  private onTouched = () => {};
}
