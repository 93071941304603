import { type ValidatorFn, type UntypedFormControl } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function GooglePhoneValidator(codeControlName, phoneControlName): ValidatorFn {
  return (form: UntypedFormControl): Record<string, any> => {
    let isNumberValid = false;
    const codeControl = form.get(codeControlName);
    const phoneControl = form.get(phoneControlName);
    try {
      const fullNumber = codeControl.value + phoneControl.value;

      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(fullNumber);
      isNumberValid = phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {}

    if (!isNumberValid) {
      phoneControl.setErrors({ ...phoneControl.errors, invalid: true });
    } else {
      phoneControl.setErrors(null);
    }

    return isNumberValid ? null : { invalid: true };
  };
}
